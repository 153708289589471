import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import tw from 'twin.macro'

import { MainLayout } from '@common/layouts'
import { PaginationLayout } from '@common/layouts'

export const query = graphql`
query QuestionsAndAnswers($skip: Int!, $limit: Int!) {
  allQuestion(
    sort: { fields: [image___root, publishedAt], order: [ASC, DESC] }
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        title
        strapiId
        slug
        publishedAt(formatString: "MMMM D, YYYY")
        image {
          childImageSharp {
            fluid(quality: 50, maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        childMarkdownRemark {
          excerpt(pruneLength: 80)
        }
      }
    }
  }
}
`

const ListWrapper = styled.div`
  ${tw`flex-1 grid grid-cols-1 gap-16 my-8 mx-8`}
  ${tw`md:(grid-cols-2 my-12)`}
  ${tw`lg:(grid-cols-3)`}
`

const ImageWrapper = styled.div`
  ${tw`relative w-full mb-4`}
`

const QuestionsListPage = ({ data: { allQuestion }, pageContext, Layout = MainLayout }) => {
  const { currentPage, numPages } = pageContext;

  return (
    <PaginationLayout title={`Questions and Answers`} path={'questions-and-answers'} currentPage={currentPage} numPages={numPages} Layout={Layout}>
      <ListWrapper>
        {allQuestion.edges.map(({ node: question }) => (
          <Link
            to={`/questions-and-answers/${question.slug}`}
            title={`Question: ${question.title}`}
            tw="overflow-hidden cursor-pointer"
            key={question.id}
          >
            <article>
              {question.image && (
                <ImageWrapper>
                  <Img
                    tw="h-full w-full"
                    fluid={{
                      ...question.image.childImageSharp.fluid,
                      aspectRatio: 4 / 3,
                    }}
                    alt={question.title}
                  />
                </ImageWrapper>
              )}

              <h2 tw="text-xl text-gray-900 font-bold tracking-tight leading-6 mb-2">
                {question.title}
              </h2>
              <p tw="text-sm text-gray-800 leading-6">
                {question.childMarkdownRemark.excerpt}
              </p>
            </article>
          </Link>
        ))}
      </ListWrapper>
    </PaginationLayout>
  )
}

export default QuestionsListPage
